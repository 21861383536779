import "pell/dist/pell.min.css"
import "leaflet/dist/leaflet.css";
import "./main.scss";

import { Elm } from "./Main.elm";
import "./editor.js";
import * as map from "./map.js"

var storageKey = "store";

var locallyStored = JSON.parse(window.localStorage.getItem(storageKey)) || {
  accessToken: null
};

var app = Elm.Main.init({
  node: document.getElementById("root"),
  flags: {
    accessToken: locallyStored.accessToken
  }
});

app.ports.storeSession.subscribe(function (val) {
  if (val === null) {
    window.localStorage.removeItem(storageKey);
  } else {
    window.localStorage.setItem(storageKey, JSON.stringify(val));
  }

  // Report that the new session was stored succesfully.
  setTimeout(function () {
    app.ports.onSessionChange.send(val);
  }, 0);
});

app.ports.openWindow.subscribe(function (url) {
  window.open(url, '_blank');
});

app.ports.renderGroundplan.subscribe(params => {
  requestAnimationFrame(() => {
    map.renderGroundplan(params, app.ports.groundplanClicked.send)
  })
})

app.ports.renderMap.subscribe(params => {
  requestAnimationFrame(() => {
    map.renderMap(params)
  })
})

app.ports.updateGroundplan.subscribe(params => {
  requestAnimationFrame(() => {
    map.updateGroundplan(params)
  })
})

// Whenever localStorage changes in another tab, report it if necessary.
window.addEventListener(
  "storage",
  function (event) {
    if (event.storageArea === window.localStorage && event.key === storageKey) {
      app.ports.onSessionChange.send(JSON.parse(event.newValue));
    }
  },
  false
);

