import "leaflet";

const markerConfig = {
  // size of the icon
  iconSize: [39, 53],
  // point of the icon which will correspond to marker's location
  iconAnchor: [19, 51],
  // point from which the popup should open relative to the iconAnchor
  popupAnchor: [0, -41]
};

const markerIcons = {
  simpleMarker: L.icon({
    ...markerConfig,
    iconUrl: "/simple-marker.png"
  }),
  greySimpleMarker: L.icon({
    ...markerConfig,
    iconUrl: "/simple-marker-grey.png"
  })
};

const tileSource =
  "https://nordkirche-osm.delink-server.net/map/{z}/{x}/{y}.png";
const attributionString = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

let map;
let mapMarkers;
export const renderMap = (params) => {
  const { latitude, longitude } = params;

  map = L.map("map", {
    attributionControl: false
  }).setView([latitude, longitude], 9);

  L.tileLayer(tileSource, {
    attribution: attributionString,
    maxZoom: 18
  }).addTo(map);

  if (mapMarkers) mapMarkers.clearLayers();
  mapMarkers = L.featureGroup();

  const marker = L.marker([latitude, longitude], {
    icon: markerIcons.simpleMarker
  });

  mapMarkers.addLayer(marker);
  map.addLayer(mapMarkers);
};

let groundplan;
let groundplanMarkers;
export const renderGroundplan = (params, sendClickedPosition) => {
  groundplan = L.map("church-groundplan", {
    attributionControl: false,
    crs: L.CRS.Simple,
    minZoom: -5 //TODO: Calculate minZoom from GroundplanSize or something
  });

  var bounds = [[0, 0], [1000, 1000]];
  L.imageOverlay(params.groundplanImageUrl, bounds, { className: "leaflet-groundplan-image" }).addTo(groundplan);
  groundplan.fitBounds(bounds);

  groundplan.setMaxBounds(bounds);
  groundplan.on("drag", function () {
    groundplan.panInsideBounds(bounds, { animate: false });
  });

  groundplan.on("click", e => sendClickedPosition(e.latlng));

  placeGroundplanMarkers(params.currentPoi, params.otherPois)
};

export const updateGroundplan = (params) => {
  placeGroundplanMarkers(params.currentPoi, params.otherPois)
}

const placeGroundplanMarkers = (currentPoi, otherPois) => {
  if (groundplanMarkers) groundplanMarkers.clearLayers();
  groundplanMarkers = L.featureGroup();
  otherPois.forEach(poi => {
    const poiMarker = L.marker([poi.yCoord, poi.xCoord], {
      icon: markerIcons.greySimpleMarker
    }).addTo(groundplan);
    groundplanMarkers.addLayer(poiMarker);
  });

  const poiMarker = L.marker([currentPoi.yCoord, currentPoi.xCoord], {
    icon: markerIcons.simpleMarker
  }).addTo(groundplan);
  groundplanMarkers.addLayer(poiMarker);

  groundplan.addLayer(groundplanMarkers);
}
