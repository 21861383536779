
import pell from "pell";
import rangy from "rangy/lib/rangy-core.js"
import * as linkify from 'linkifyjs';


var getComputedDisplay = (typeof window.getComputedStyle != "undefined") ?
    function (el) {
        return window.getComputedStyle(el, null).display;
    } :
    function (el) {
        return el.currentStyle.display;
    };

function replaceWithOwnChildren(el) {
    var parent = el.parentNode;
    while (el.hasChildNodes()) {
        parent.insertBefore(el.firstChild, el);
    }
    parent.removeChild(el);
}

function removeSelectionFormatting() {
    var sel = rangy.getSelection();

    if (!sel.isCollapsed) {
        for (var i = 0, range; i < sel.rangeCount; ++i) {
            range = sel.getRangeAt(i);

            var allEls = range.getNodes();

            // Start with all selected elements
            var formattingEls = range.getNodes([1]);

            // And take the first one of those (which is one of the top-most)
            // OR can also be just some selected text
            var currentEl = allEls[0].parentNode;

            // And go up the parent-chain to remove all inherited formatting
            while (!currentEl.classList.contains("pell-content")) {
                formattingEls.push(currentEl);
                currentEl = currentEl.parentNode;
            }

            // Remove the formatting elements
            for (var i = 0, el; el = formattingEls[i]; i++) {
                if (getComputedDisplay(el) != "inline") {
                    var newEl = document.createElement("div");
                    el.parentNode.insertBefore(newEl, el);
                    newEl.appendChild(el)
                    replaceWithOwnChildren(el);
                } else if (el.tagName != "BR") {
                    replaceWithOwnChildren(el);
                }
            }
        }
    }
}

window.customElements.define(
    "elph-editor",
    class extends HTMLElement {
        static get observedAttributes() {
            return ["elpheditorcontent"];
        }

        connectedCallback() {
            this.instance = pell.init({
                element: this,
                onChange: content => {
                    this.dispatchEvent(
                        new CustomEvent("htmlEditorContentUpdated", {
                            detail: content
                        }))
                },
                actions: [
                    {
                        name: "justifyLeft",
                        icon: "<i class='fas fa-align-left'></i>",
                        title: "Linksbündig",
                        result: () => {
                            pell.exec('justifyLeft')
                        }
                    },
                    {
                        name: "justifyCenter",
                        icon: "<i class='fas fa-align-center'></i>",
                        title: "Zentriert",
                        result: () => {
                            pell.exec('justifyCenter')
                        }
                    },
                    {
                        name: "bold",
                        icon: "<i class='fas fa-bold'></i>",
                        title: "Fett"
                    },
                    {
                        name: "italic",
                        icon: "<i class='fas fa-italic'></i>",
                        title: "Kursiv"
                    },
                    {
                        name: "quote",
                        icon: "<i class='fas fa-quote-right'></i>",
                        title: "Zitat"
                    },
                    {
                        name: "ulist",
                        icon: "<i class='fas fa-list'></i>",
                        title: "Liste"
                    },


                    {
                        name: "link",
                        icon: "<i class='fas fa-link'></i>",
                        title: "Link einfügen",
                        result: () => {
                            const url = window.prompt('Enter the link URL');
                            if (url != null) {
                                const links = linkify.find(url);
                                if (linkify.test(url) && links.length == 1) {
                                    pell.exec('createLink', links[0].href)
                                } else {
                                    window.alert(`Invalid URL "${url}"`)
                                }
                            }
                        }
                    },
                    {
                        icon: "<i class='fas fa-remove-format'></i>",
                        title: "Formatierung entfernen",
                        result: () => {
                            removeSelectionFormatting();
                            pell.exec("removeFormat");
                            this.dispatchEvent(
                                new CustomEvent("htmlEditorContentUpdated", {
                                    detail: this.instance.content.innerHTML
                                }))
                        }
                    },
                    {
                        name: 'heading1',
                        icon: "<b>H<sub>1</sub></b>",
                        title: "Überschrift 1",
                        result: () => {
                            pell.exec('formatBlock', 'h1')
                        }
                    },
                    {
                        name: 'heading2',
                        icon: "<b>H<sub>2</sub></b>",
                        title: "Überschrift 2",
                        result: () => {
                            pell.exec('formatBlock', 'h2')
                        }
                    },
                    {
                        name: 'heading3',
                        icon: "<b>H<sub>3</sub></b>",
                        title: "Überschrift 3",
                        result: () => {
                            pell.exec('formatBlock', 'h3')
                        }
                    },
                    {
                        name: 'heading4',
                        icon: "<b>H<sub>4</sub></b>",
                        title: "Überschrift 4",
                        result: () => {
                            pell.exec('formatBlock', 'h4')
                        }
                    },
                    {
                        name: 'heading5',
                        icon: "<b>H<sub>5</sub></b>",
                        title: "Überschrift 5",
                        result: () => {
                            pell.exec('formatBlock', 'h5')
                        }
                    },
                    {
                        name: 'heading6',
                        icon: "<b>H<sub>6</sub></b>",
                        title: "Überschrift 6",
                        result: () => {
                            pell.exec('formatBlock', 'h6')
                        }
                    },
                    {
                        name: 'source',
                        icon: "<i class='fas fa-comment'></i>",
                        title: "Quelle",
                        result: () => {
                            pell.exec('subscript')

                        }
                    }
                ]
            });

            this.instance.content.innerHTML = this.getAttribute("elpheditorcontent");
        }
    }
);
