module Main exposing (Model(..), main)

import Browser exposing (Document)
import Browser.Navigation as Nav
import Flash
import Leaflet
import Model.Content exposing (ChurchContent, Content, GenericRegionTopicContent, PoiContainerContent, PoiContent, RegionContent, RegionTopicContainerContent)
import Model.NapiChurchData exposing (NapiChurchData)
import Page
import Page.Accounts.Edit as AccountsEdit
import Page.Accounts.Index as AccountsIndex
import Page.Accounts.New as AccountsNew
import Page.Accounts.Show as AccountsShow
import Page.Blank as Blank
import Page.Churches.Edit as ChurchesEdit
import Page.Churches.EditDescription as ChurchesEditDescription
import Page.Churches.Index as ChurchesIndex
import Page.Churches.Show as ChurchesShow
import Page.Churches.ShowImage as ChurchesShowImage
import Page.Churches.ShowMap as ChurchesShowMap
import Page.Login as Login
import Page.MediaGallery.Index as MediaGalleryIndex
import Page.MediaGallery.Show as MediaGalleryShow
import Page.MediaGallery.Upload as MediaGalleryUpload
import Page.NotFound as NotFound
import Page.PasswordForgotten as PasswordForgotten
import Page.Poi.Edit as PoiEdit
import Page.Poi.EditBelieving as PoiEditBelieving
import Page.Poi.EditExperiencing as PoiEditExperiencing
import Page.Poi.EditImages as PoiEditImages
import Page.Poi.EditKnowing as PoiEditKnowing
import Page.Poi.Show as PoiShow
import Page.Pois.Edit as PoisEdit
import Page.Pois.Index as PoisIndex
import Page.Regions.Edit as RegionsEdit
import Page.Regions.EditChurches as RegionsEditChurches
import Page.Regions.EditDescription as RegionsEditDescription
import Page.Regions.EditImages as RegionsEditImages
import Page.Regions.Index as RegionsIndex
import Page.Regions.New as RegionsNew
import Page.Regions.Show as RegionsShow
import Page.Regions.ShowMap as RegionsShowMap
import Page.StaticPages.Edit as StaticPagesEdit
import Page.StaticPages.Index as StaticPagesIndex
import Page.StaticPages.Show as StaticPagesShow
import Page.Topic.Edit as TopicEdit
import Page.Topic.Show as TopicShow
import Page.Topics.Edit as TopicsEdit
import Page.Topics.Index as TopicsIndex
import RemoteData
import Route exposing (Route)
import Session exposing (Flags, Session)
import Url exposing (Url)
import Views.Accounts.Details as AccountsDetailsView
import Views.Accounts.List as AccountsList
import Views.Churches.List as ChurchesList
import Views.MediaGallery.List as MediaGalleryList
import Views.Regions.List as RegionsList
import Views.StaticPages.List as StaticPagesList



---- MODEL ----


type Model
    = AccountsIndex AccountsIndex.Model
    | AccountsEdit AccountsEdit.Model
    | AccountsShow AccountsShow.Model
    | AccountsNew AccountsNew.Model
    | ChurchesIndex ChurchesIndex.Model
    | ChurchesEdit ChurchesEdit.Model
    | ChurchesEditDescription ChurchesEditDescription.Model
    | ChurchesShow ChurchesShow.Model
    | ChurchesShowImage ChurchesShowImage.Model
    | ChurchesShowMap ChurchesShowMap.Model
    | MediaGalleryIndex MediaGalleryIndex.Model
    | MediaGalleryShow MediaGalleryShow.Model
    | MediaGalleryUpload MediaGalleryUpload.Model
    | PoisIndex PoisIndex.Model
    | PoisEdit PoisEdit.Model
    | PoiShow PoiShow.Model
    | PoiEdit PoiEdit.Model
    | PoiEditImages PoiEditImages.Model
    | PoiEditKnowing PoiEditKnowing.Model
    | PoiEditBelieving PoiEditBelieving.Model
    | PoiEditExperiencing PoiEditExperiencing.Model
    | StaticPagesIndex StaticPagesIndex.Model
    | StaticPagesEdit StaticPagesEdit.Model
    | StaticPagesShow StaticPagesShow.Model
    | RegionsIndex RegionsIndex.Model
    | RegionsShow RegionsShow.Model
    | RegionsShowMap RegionsShowMap.Model
    | RegionsNew RegionsNew.Model
    | RegionsEdit RegionsEdit.Model
    | RegionsEditChurches RegionsEditChurches.Model
    | RegionsEditDescription RegionsEditDescription.Model
    | RegionsEditImages RegionsEditImages.Model
    | TopicsIndex TopicsIndex.Model
    | TopicsEdit TopicsEdit.Model
    | TopicShow TopicShow.Model
    | TopicEdit TopicEdit.Model
    | Login Login.Model
    | NotFound Session
    | PasswordForgotten PasswordForgotten.Model
      -- A state that shows an empty Page during URL-Changes
    | Redirect Session


init : Flags -> Url -> Nav.Key -> ( Model, Cmd Msg )
init flags url navKey =
    let
        ( model, cmdChangeRoute ) =
            changeRouteTo (Route.fromUrl url)
                (Redirect (Session.build navKey flags))
    in
    ( model, cmdChangeRoute )


changeRouteTo : Maybe Route -> Model -> ( Model, Cmd Msg )
changeRouteTo maybeRoute model =
    let
        session =
            toSession model
    in
    if Session.isLoggedIn session then
        case maybeRoute of
            Nothing ->
                ( NotFound session, Cmd.none )

            -- Session management
            Just Route.Login ->
                ( model, Route.replaceUrl (Session.navKey session) Route.Home )

            Just Route.PasswordForgotten ->
                ( model, Route.replaceUrl (Session.navKey session) Route.Home )

            Just Route.Logout ->
                ( model, Session.logout )

            -- Actual Pages
            Just Route.Home ->
                ( model, Route.replaceUrl (Session.navKey session) (Route.ChurchesIndex 1) )

            Just (Route.AccountsIndex page) ->
                AccountsIndex.init session page
                    |> updateWith AccountsIndex GotAccountsIndexMsg

            Just (Route.AccountsShow id) ->
                let
                    accountsList =
                        toAccountsList model
                in
                AccountsShow.init session id accountsList
                    |> updateWith AccountsShow GotAccountsShowMsg

            Just (Route.AccountsEdit id) ->
                let
                    accountsList =
                        toAccountsList model

                    accountsDetails =
                        toAccountsDetails model
                in
                AccountsEdit.init session id accountsList accountsDetails
                    |> updateWith AccountsEdit GotAccountsEditMsg

            Just Route.AccountsNew ->
                let
                    accountsList =
                        toAccountsList model
                in
                AccountsNew.init session accountsList
                    |> updateWith AccountsNew GotAccountsNewMsg

            Just (Route.ChurchesIndex page) ->
                let
                    churchesList =
                        toChurchesList model
                in
                ChurchesIndex.init session page churchesList
                    |> updateWith ChurchesIndex GotChurchesIndexMsg

            Just (Route.ChurchesShow id) ->
                let
                    churchesList =
                        toChurchesList model
                in
                ChurchesShow.init session id churchesList
                    |> updateWith ChurchesShow GotChurchesShowMsg

            Just (Route.ChurchesEdit id) ->
                let
                    churchesList =
                        toChurchesList model

                    church =
                        toChurch model

                    napiChurchData =
                        toNapiChurchData model
                in
                ChurchesEdit.init session id churchesList church napiChurchData
                    |> updateWith ChurchesEdit GotChurchesEditMsg

            Just (Route.ChurchesShowImage id) ->
                let
                    churchesList =
                        toChurchesList model

                    church =
                        toChurch model

                    napiChurchData =
                        toNapiChurchData model
                in
                ChurchesShowImage.init session id churchesList church napiChurchData
                    |> updateWith ChurchesShowImage GotChurchesShowImageMsg

            Just (Route.ChurchesShowMap id) ->
                let
                    churchesList =
                        toChurchesList model

                    church =
                        toChurch model

                    napiChurchData =
                        toNapiChurchData model
                in
                ChurchesShowMap.init session id churchesList church napiChurchData
                    |> updateWith ChurchesShowMap GotChurchesShowMapMsg

            Just (Route.ChurchesEditDescription id) ->
                let
                    churchesList =
                        toChurchesList model

                    church =
                        toChurch model

                    napiChurchData =
                        toNapiChurchData model
                in
                ChurchesEditDescription.init session id churchesList church napiChurchData
                    |> updateWith ChurchesEditDescription GotChurchesEditDescriptionMsg

            Just (Route.MediaGalleryIndex page) ->
                let
                    mediaGalleryListModel =
                        toMediaGalleryListModel model
                in
                MediaGalleryIndex.init session page mediaGalleryListModel
                    |> updateWith MediaGalleryIndex GotMediaGalleryIndexMsg

            Just (Route.MediaGalleryShow page) ->
                let
                    mediaGalleryListModel =
                        toMediaGalleryListModel model
                in
                MediaGalleryShow.init session page mediaGalleryListModel
                    |> updateWith MediaGalleryShow GotMediaGalleryShowMsg

            Just Route.MediaGalleryUpload ->
                MediaGalleryUpload.init session
                    |> updateWith MediaGalleryUpload GotMediaGalleryUploadMsg

            Just (Route.PoisIndex churchId) ->
                let
                    church =
                        toChurch model
                in
                PoisIndex.init session churchId church
                    |> updateWith PoisIndex GotPoisIndexMsg

            Just (Route.PoisEdit churchId) ->
                let
                    church =
                        toChurch model

                    poiContainer =
                        toPoiContainer model
                in
                PoisEdit.init session churchId church poiContainer
                    |> updateWith PoisEdit GotPoisEditMsg

            Just (Route.PoiShow churchId poiId) ->
                let
                    church =
                        toChurch model

                    poiContainer =
                        toPoiContainerForShow model
                in
                PoiShow.init session churchId poiId church poiContainer
                    |> updateWith PoiShow GotPoiShowMsg

            Just (Route.PoiEdit churchId poiId) ->
                let
                    church =
                        toChurch model

                    poiContainer =
                        toPoiContainer model

                    poi =
                        toPoi model
                in
                PoiEdit.init session churchId poiId church poiContainer poi
                    |> updateWith PoiEdit GotPoiEditMsg

            Just (Route.PoiEditImages churchId poiId) ->
                let
                    church =
                        toChurch model

                    poiContainer =
                        toPoiContainer model

                    poi =
                        toPoi model
                in
                PoiEditImages.init session churchId poiId church poiContainer poi
                    |> updateWith PoiEditImages GotPoiEditImagesMsg

            Just (Route.PoiEditKnowing churchId poiId) ->
                let
                    church =
                        toChurch model

                    poiContainer =
                        toPoiContainer model

                    poi =
                        toPoi model
                in
                PoiEditKnowing.init session churchId poiId church poiContainer poi
                    |> updateWith PoiEditKnowing GotPoiEditKnowingMsg

            Just (Route.PoiEditBelieving churchId poiId) ->
                let
                    church =
                        toChurch model

                    poiContainer =
                        toPoiContainer model

                    poi =
                        toPoi model
                in
                PoiEditBelieving.init session churchId poiId church poiContainer poi
                    |> updateWith PoiEditBelieving GotPoiEditBelievingMsg

            Just (Route.PoiEditExperiencing churchId poiId) ->
                let
                    church =
                        toChurch model

                    poiContainer =
                        toPoiContainer model

                    poi =
                        toPoi model
                in
                PoiEditExperiencing.init session churchId poiId church poiContainer poi
                    |> updateWith PoiEditExperiencing GotPoiEditExperiencingMsg

            Just (Route.RegionsIndex page) ->
                let
                    regionsList =
                        toRegionsList model
                in
                RegionsIndex.init session page regionsList
                    |> updateWith RegionsIndex GotRegionsIndexMsg

            Just Route.RegionsNew ->
                let
                    regionsList =
                        toRegionsList model
                in
                RegionsNew.init session regionsList
                    |> updateWith RegionsNew GotRegionsNewMsg

            Just (Route.RegionsShow id) ->
                let
                    regionsList =
                        toRegionsList model
                in
                RegionsShow.init session id regionsList
                    |> updateWith RegionsShow GotRegionsShowMsg

            Just (Route.RegionsShowMap id) ->
                let
                    regionsList =
                        toRegionsList model

                    region =
                        toRegion model
                in
                RegionsShowMap.init session id regionsList region
                    |> updateWith RegionsShowMap GotRegionsShowMapMsg

            Just (Route.RegionsEdit id) ->
                let
                    regionsList =
                        toRegionsList model

                    region =
                        toRegion model
                in
                RegionsEdit.init session id regionsList region
                    |> updateWith RegionsEdit GotRegionsEditMsg

            Just (Route.RegionsEditChurches id) ->
                let
                    regionsList =
                        toRegionsList model

                    region =
                        toRegion model
                in
                RegionsEditChurches.init session id regionsList region
                    |> updateWith RegionsEditChurches GotRegionsEditChurchesMsg

            Just (Route.RegionsEditDescription id) ->
                let
                    regionsList =
                        toRegionsList model

                    region =
                        toRegion model
                in
                RegionsEditDescription.init session id regionsList region
                    |> updateWith RegionsEditDescription GotRegionsEditDescriptionMsg

            Just (Route.RegionsEditImages id) ->
                let
                    regionsList =
                        toRegionsList model

                    region =
                        toRegion model
                in
                RegionsEditImages.init session id regionsList region
                    |> updateWith RegionsEditImages GotRegionsEditImagesMsg

            Just (Route.TopicsIndex regionId) ->
                let
                    region =
                        toRegion model
                in
                TopicsIndex.init session regionId region
                    |> updateWith TopicsIndex GotTopicsIndexMsg

            Just (Route.TopicsEdit regionId) ->
                let
                    region =
                        toRegion model

                    regionTopicContainer =
                        toRegionTopicContainer model
                in
                TopicsEdit.init session regionId region regionTopicContainer
                    |> updateWith TopicsEdit GotTopicsEditMsg

            Just (Route.TopicShow regionId topicId) ->
                let
                    region =
                        toRegion model

                    topicContainer =
                        toRegionTopicContainerForShow model
                in
                TopicShow.init session regionId topicId region topicContainer
                    |> updateWith TopicShow GotTopicShowMsg

            Just (Route.TopicEdit regionId topicId) ->
                let
                    region =
                        toRegion model

                    topicContainer =
                        toRegionTopicContainer model

                    topic =
                        toGenericRegionTopic model
                in
                TopicEdit.init session regionId topicId region topicContainer topic
                    |> updateWith TopicEdit GotTopicEditMsg

            Just (Route.StaticPagesIndex page) ->
                StaticPagesIndex.init session page
                    |> updateWith StaticPagesIndex GotStaticPagesIndexMsg

            Just (Route.StaticPagesShow id) ->
                let
                    staticPagesList =
                        toStaticPagesList model
                in
                StaticPagesShow.init session id staticPagesList
                    |> updateWith StaticPagesShow GotStaticPagesShowMsg

            Just (Route.StaticPagesEdit id) ->
                let
                    staticPagesList =
                        toStaticPagesList model

                    staticPage =
                        toStaticPage model
                in
                StaticPagesEdit.init session id staticPagesList staticPage
                    |> updateWith StaticPagesEdit GotStaticPagesEditMsg

    else
        case maybeRoute of
            Just Route.PasswordForgotten ->
                PasswordForgotten.init session
                    |> updateWith PasswordForgotten GotPasswordForgottenMsg

            _ ->
                Login.init session
                    |> updateWith Login GotLoginMsg



---- UPDATE ----


type Msg
    = Ignored
    | ChangedUrl Url
    | ClickedLink Browser.UrlRequest
    | GotAccountsIndexMsg AccountsIndex.Msg
    | GotAccountsShowMsg AccountsShow.Msg
    | GotAccountsEditMsg AccountsEdit.Msg
    | GotAccountsNewMsg AccountsNew.Msg
    | GotChurchesIndexMsg ChurchesIndex.Msg
    | GotChurchesEditMsg ChurchesEdit.Msg
    | GotChurchesEditDescriptionMsg ChurchesEditDescription.Msg
    | GotChurchesShowMsg ChurchesShow.Msg
    | GotChurchesShowImageMsg ChurchesShowImage.Msg
    | GotChurchesShowMapMsg ChurchesShowMap.Msg
    | GotMediaGalleryIndexMsg MediaGalleryIndex.Msg
    | GotMediaGalleryShowMsg MediaGalleryShow.Msg
    | GotMediaGalleryUploadMsg MediaGalleryUpload.Msg
    | GotPoisIndexMsg PoisIndex.Msg
    | GotPoisEditMsg PoisEdit.Msg
    | GotPoiShowMsg PoiShow.Msg
    | GotPoiEditMsg PoiEdit.Msg
    | GotPoiEditImagesMsg PoiEditImages.Msg
    | GotPoiEditKnowingMsg PoiEditKnowing.Msg
    | GotPoiEditBelievingMsg PoiEditBelieving.Msg
    | GotPoiEditExperiencingMsg PoiEditExperiencing.Msg
    | GotRegionsIndexMsg RegionsIndex.Msg
    | GotRegionsShowMsg RegionsShow.Msg
    | GotRegionsShowMapMsg RegionsShowMap.Msg
    | GotRegionsNewMsg RegionsNew.Msg
    | GotRegionsEditMsg RegionsEdit.Msg
    | GotRegionsEditChurchesMsg RegionsEditChurches.Msg
    | GotRegionsEditDescriptionMsg RegionsEditDescription.Msg
    | GotRegionsEditImagesMsg RegionsEditImages.Msg
    | GotTopicsIndexMsg TopicsIndex.Msg
    | GotTopicsEditMsg TopicsEdit.Msg
    | GotTopicShowMsg TopicShow.Msg
    | GotTopicEditMsg TopicEdit.Msg
    | GotStaticPagesIndexMsg StaticPagesIndex.Msg
    | GotStaticPagesEditMsg StaticPagesEdit.Msg
    | GotStaticPagesShowMsg StaticPagesShow.Msg
    | GotLoginMsg Login.Msg
    | GotPasswordForgottenMsg PasswordForgotten.Msg
    | GotSession Session
    | SessionMsg Session.Msg


updateSession : Session.Msg -> Model -> ( Model, Cmd Msg )
updateSession msg page =
    let
        ( session, subMsg ) =
            Session.update msg (toSession page)

        updatedPage =
            case page of
                AccountsShow model ->
                    AccountsShow { model | session = session }

                AccountsEdit model ->
                    AccountsEdit { model | session = session }

                AccountsIndex model ->
                    AccountsIndex { model | session = session }

                AccountsNew model ->
                    AccountsNew { model | session = session }

                ChurchesShow model ->
                    ChurchesShow { model | session = session }

                ChurchesShowImage model ->
                    ChurchesShowImage { model | session = session }

                ChurchesShowMap model ->
                    ChurchesShowMap { model | session = session }

                ChurchesEdit model ->
                    ChurchesEdit { model | session = session }

                ChurchesEditDescription model ->
                    ChurchesEditDescription { model | session = session }

                ChurchesIndex model ->
                    ChurchesIndex { model | session = session }

                MediaGalleryIndex model ->
                    MediaGalleryIndex { model | session = session }

                MediaGalleryShow model ->
                    MediaGalleryShow { model | session = session }

                MediaGalleryUpload model ->
                    MediaGalleryUpload { model | session = session }

                PoisEdit model ->
                    PoisEdit { model | session = session }

                PoisIndex model ->
                    PoisIndex { model | session = session }

                PoiShow model ->
                    PoiShow { model | session = session }

                PoiEdit model ->
                    PoiEdit { model | session = session }

                PoiEditImages model ->
                    PoiEditImages { model | session = session }

                PoiEditKnowing model ->
                    PoiEditKnowing { model | session = session }

                PoiEditBelieving model ->
                    PoiEditBelieving { model | session = session }

                PoiEditExperiencing model ->
                    PoiEditExperiencing { model | session = session }

                RegionsIndex model ->
                    RegionsIndex { model | session = session }

                RegionsShowMap model ->
                    RegionsShowMap { model | session = session }

                RegionsShow model ->
                    RegionsShow { model | session = session }

                RegionsNew model ->
                    RegionsNew { model | session = session }

                RegionsEdit model ->
                    RegionsEdit { model | session = session }

                RegionsEditChurches model ->
                    RegionsEditChurches { model | session = session }

                RegionsEditDescription model ->
                    RegionsEditDescription { model | session = session }

                RegionsEditImages model ->
                    RegionsEditImages { model | session = session }

                TopicsEdit model ->
                    TopicsEdit { model | session = session }

                TopicsIndex model ->
                    TopicsIndex { model | session = session }

                TopicShow model ->
                    TopicShow { model | session = session }

                TopicEdit model ->
                    TopicEdit { model | session = session }

                StaticPagesShow model ->
                    StaticPagesShow { model | session = session }

                StaticPagesEdit model ->
                    StaticPagesEdit { model | session = session }

                StaticPagesIndex model ->
                    StaticPagesIndex { model | session = session }

                Login model ->
                    Login { model | session = session }

                NotFound _ ->
                    NotFound session

                PasswordForgotten model ->
                    PasswordForgotten { model | session = session }

                Redirect _ ->
                    Redirect session
    in
    ( updatedPage, Cmd.map SessionMsg subMsg )


toAccountsList : Model -> Maybe AccountsList.Model
toAccountsList model =
    case model of
        AccountsIndex subModel ->
            Just subModel.accountsListModel

        AccountsEdit subModel ->
            Just subModel.accountsListModel

        AccountsShow subModel ->
            Just subModel.accountsListModel

        AccountsNew subModel ->
            Just subModel.accountsListModel

        _ ->
            Nothing


toAccountsDetails : Model -> Maybe AccountsDetailsView.Model
toAccountsDetails model =
    case model of
        AccountsShow subModel ->
            Just subModel.accountsDetailsModel

        AccountsEdit subModel ->
            Just subModel.accountsDetailsModel

        _ ->
            Nothing


toChurchesList : Model -> Maybe ChurchesList.Model
toChurchesList model =
    case model of
        ChurchesIndex subModel ->
            Just subModel.churchesListModel

        ChurchesEdit subModel ->
            Just subModel.churchesListModel

        ChurchesEditDescription subModel ->
            Just subModel.churchesListModel

        ChurchesShow subModel ->
            Just subModel.churchesListModel

        ChurchesShowImage subModel ->
            Just subModel.churchesListModel

        ChurchesShowMap subModel ->
            Just subModel.churchesListModel

        _ ->
            Nothing


toChurch : Model -> Maybe ChurchContent
toChurch model =
    case model of
        ChurchesEdit subModel ->
            RemoteData.toMaybe subModel.church

        ChurchesEditDescription subModel ->
            RemoteData.toMaybe subModel.church

        ChurchesShow subModel ->
            RemoteData.toMaybe subModel.church

        ChurchesShowImage subModel ->
            RemoteData.toMaybe subModel.church

        ChurchesShowMap subModel ->
            RemoteData.toMaybe subModel.church

        PoisIndex subModel ->
            RemoteData.toMaybe subModel.church

        PoisEdit subModel ->
            RemoteData.toMaybe subModel.church

        PoiShow subModel ->
            RemoteData.toMaybe subModel.church

        PoiEdit subModel ->
            RemoteData.toMaybe subModel.church

        PoiEditImages subModel ->
            RemoteData.toMaybe subModel.church

        PoiEditKnowing subModel ->
            RemoteData.toMaybe subModel.church

        PoiEditBelieving subModel ->
            RemoteData.toMaybe subModel.church

        PoiEditExperiencing subModel ->
            RemoteData.toMaybe subModel.church

        _ ->
            Nothing


toNapiChurchData : Model -> Maybe NapiChurchData
toNapiChurchData model =
    case model of
        ChurchesEdit subModel ->
            RemoteData.toMaybe subModel.napiChurchData

        ChurchesShow subModel ->
            RemoteData.toMaybe subModel.napiChurchData

        ChurchesShowImage subModel ->
            RemoteData.toMaybe subModel.napiChurchData

        ChurchesShowMap subModel ->
            RemoteData.toMaybe subModel.napiChurchData

        ChurchesEditDescription subModel ->
            RemoteData.toMaybe subModel.napiChurchData

        _ ->
            Nothing


toPoiContainer : Model -> Maybe PoiContainerContent
toPoiContainer model =
    case model of
        PoisIndex subModel ->
            RemoteData.toMaybe subModel.poiContainer

        PoisEdit subModel ->
            RemoteData.toMaybe subModel.poiContainer

        PoiShow subModel ->
            RemoteData.toMaybe subModel.poiContainer

        PoiEdit subModel ->
            RemoteData.toMaybe subModel.poiContainer

        PoiEditImages subModel ->
            RemoteData.toMaybe subModel.poiContainer

        PoiEditKnowing subModel ->
            RemoteData.toMaybe subModel.poiContainer

        PoiEditBelieving subModel ->
            RemoteData.toMaybe subModel.poiContainer

        PoiEditExperiencing subModel ->
            RemoteData.toMaybe subModel.poiContainer

        _ ->
            Nothing


toPoiContainerForShow : Model -> Maybe PoiContainerContent
toPoiContainerForShow model =
    case model of
        PoiEdit _ ->
            Nothing

        _ ->
            toPoiContainer model


toPoi : Model -> Maybe PoiContent
toPoi model =
    case model of
        PoiShow subModel ->
            RemoteData.toMaybe subModel.poi

        PoiEdit subModel ->
            RemoteData.toMaybe subModel.poi

        PoiEditImages subModel ->
            RemoteData.toMaybe subModel.poi

        PoiEditKnowing subModel ->
            RemoteData.toMaybe subModel.poi

        PoiEditBelieving subModel ->
            RemoteData.toMaybe subModel.poi

        PoiEditExperiencing subModel ->
            RemoteData.toMaybe subModel.poi

        _ ->
            Nothing


toRegionsList : Model -> Maybe RegionsList.Model
toRegionsList model =
    case model of
        RegionsIndex subModel ->
            Just subModel.regionsListModel

        RegionsShow subModel ->
            Just subModel.regionsListModel

        RegionsShowMap subModel ->
            Just subModel.regionsListModel

        RegionsNew subModel ->
            Just subModel.regionsListModel

        RegionsEdit subModel ->
            Just subModel.regionsListModel

        RegionsEditChurches subModel ->
            Just subModel.regionsListModel

        RegionsEditDescription subModel ->
            Just subModel.regionsListModel

        RegionsEditImages subModel ->
            Just subModel.regionsListModel

        _ ->
            Nothing


toRegion : Model -> Maybe RegionContent
toRegion model =
    case model of
        RegionsShow subModel ->
            RemoteData.toMaybe subModel.region

        RegionsShowMap subModel ->
            RemoteData.toMaybe subModel.region

        RegionsEdit subModel ->
            RemoteData.toMaybe subModel.region

        RegionsEditChurches subModel ->
            RemoteData.toMaybe subModel.region

        RegionsEditDescription subModel ->
            RemoteData.toMaybe subModel.region

        RegionsEditImages subModel ->
            RemoteData.toMaybe subModel.region

        TopicsIndex subModel ->
            RemoteData.toMaybe subModel.region

        TopicsEdit subModel ->
            RemoteData.toMaybe subModel.region

        TopicShow subModel ->
            RemoteData.toMaybe subModel.region

        TopicEdit subModel ->
            RemoteData.toMaybe subModel.region

        _ ->
            Nothing


toRegionTopicContainer : Model -> Maybe RegionTopicContainerContent
toRegionTopicContainer model =
    case model of
        TopicsIndex subModel ->
            RemoteData.toMaybe subModel.topicContainer

        TopicsEdit subModel ->
            RemoteData.toMaybe subModel.topicContainer

        TopicShow subModel ->
            RemoteData.toMaybe subModel.topicContainer

        TopicEdit subModel ->
            RemoteData.toMaybe subModel.topicContainer

        _ ->
            Nothing


toRegionTopicContainerForShow : Model -> Maybe RegionTopicContainerContent
toRegionTopicContainerForShow model =
    case model of
        TopicEdit _ ->
            Nothing

        _ ->
            toRegionTopicContainer model


toGenericRegionTopic : Model -> Maybe GenericRegionTopicContent
toGenericRegionTopic model =
    case model of
        TopicShow subModel ->
            RemoteData.toMaybe subModel.topic

        TopicEdit subModel ->
            RemoteData.toMaybe subModel.topic

        _ ->
            Nothing


toStaticPagesList : Model -> Maybe StaticPagesList.Model
toStaticPagesList model =
    case model of
        StaticPagesIndex subModel ->
            Just subModel.staticPagesListModel

        StaticPagesEdit subModel ->
            Just subModel.staticPagesListModel

        StaticPagesShow subModel ->
            Just subModel.staticPagesListModel

        _ ->
            Nothing


toStaticPage : Model -> Maybe Content
toStaticPage model =
    case model of
        StaticPagesEdit subModel ->
            RemoteData.toMaybe subModel.staticPage

        StaticPagesShow subModel ->
            RemoteData.toMaybe subModel.staticPage

        _ ->
            Nothing


toMediaGalleryListModel : Model -> Maybe MediaGalleryList.Model
toMediaGalleryListModel model =
    case model of
        MediaGalleryIndex subModel ->
            Just subModel.mediaGalleryListModel

        MediaGalleryShow subModel ->
            Just subModel.mediaGalleryListModel

        _ ->
            Nothing


isDirty : Model -> Bool
isDirty model =
    case model of
        AccountsIndex _ ->
            False

        AccountsShow _ ->
            False

        AccountsEdit subModel ->
            AccountsEdit.isDirty subModel

        AccountsNew subModel ->
            AccountsNew.isDirty subModel

        ChurchesIndex _ ->
            False

        ChurchesShow _ ->
            False

        ChurchesShowImage _ ->
            False

        ChurchesShowMap _ ->
            False

        ChurchesEdit subModel ->
            ChurchesEdit.isDirty subModel

        ChurchesEditDescription subModel ->
            ChurchesEditDescription.isDirty subModel

        MediaGalleryIndex _ ->
            False

        MediaGalleryShow _ ->
            False

        MediaGalleryUpload _ ->
            False

        PoisIndex _ ->
            False

        PoisEdit subModel ->
            PoisEdit.isDirty subModel

        PoiShow _ ->
            False

        PoiEdit subModel ->
            PoiEdit.isDirty subModel

        PoiEditImages subModel ->
            PoiEditImages.isDirty subModel

        PoiEditKnowing subModel ->
            PoiEditKnowing.isDirty subModel

        PoiEditBelieving subModel ->
            PoiEditBelieving.isDirty subModel

        PoiEditExperiencing subModel ->
            PoiEditExperiencing.isDirty subModel

        RegionsIndex _ ->
            False

        RegionsShow _ ->
            False

        RegionsShowMap _ ->
            False

        RegionsNew subModel ->
            RegionsNew.isDirty subModel

        RegionsEdit subModel ->
            RegionsEdit.isDirty subModel

        RegionsEditChurches subModel ->
            RegionsEditChurches.isDirty subModel

        RegionsEditDescription subModel ->
            RegionsEditDescription.isDirty subModel

        RegionsEditImages subModel ->
            RegionsEditImages.isDirty subModel

        TopicsIndex _ ->
            False

        TopicsEdit subModel ->
            TopicsEdit.isDirty subModel

        TopicShow _ ->
            False

        TopicEdit subModel ->
            TopicEdit.isDirty subModel

        StaticPagesIndex _ ->
            False

        StaticPagesShow _ ->
            False

        StaticPagesEdit subModel ->
            StaticPagesEdit.isDirty subModel

        Login _ ->
            False

        NotFound _ ->
            False

        PasswordForgotten _ ->
            False

        Redirect _ ->
            False


toRoute : Model -> Route
toRoute model =
    case model of
        AccountsIndex subModel ->
            Route.AccountsIndex subModel.page

        AccountsShow subModel ->
            Route.AccountsShow subModel.id

        AccountsEdit subModel ->
            Route.AccountsEdit subModel.id

        AccountsNew _ ->
            Route.AccountsNew

        ChurchesIndex subModel ->
            Route.ChurchesIndex subModel.page

        ChurchesShow subModel ->
            Route.ChurchesShow subModel.id

        ChurchesShowImage subModel ->
            Route.ChurchesShowImage subModel.id

        ChurchesShowMap subModel ->
            Route.ChurchesShowMap subModel.id

        ChurchesEdit subModel ->
            Route.ChurchesEdit subModel.id

        ChurchesEditDescription subModel ->
            Route.ChurchesEditDescription subModel.id

        MediaGalleryIndex subModel ->
            Route.MediaGalleryIndex subModel.page

        MediaGalleryShow subModel ->
            Route.MediaGalleryShow subModel.mediaId

        MediaGalleryUpload _ ->
            Route.MediaGalleryUpload

        PoisIndex subModel ->
            Route.PoisIndex subModel.churchId

        PoisEdit subModel ->
            Route.PoisEdit subModel.churchId

        PoiShow subModel ->
            Route.PoiShow subModel.churchId subModel.poiId

        PoiEdit subModel ->
            Route.PoiEdit subModel.churchId subModel.poiId

        PoiEditImages subModel ->
            Route.PoiEditImages subModel.churchId subModel.poiId

        PoiEditKnowing subModel ->
            Route.PoiEditKnowing subModel.churchId subModel.poiId

        PoiEditBelieving subModel ->
            Route.PoiEditBelieving subModel.churchId subModel.poiId

        PoiEditExperiencing subModel ->
            Route.PoiEditExperiencing subModel.churchId subModel.poiId

        RegionsIndex subModel ->
            Route.RegionsIndex subModel.page

        RegionsShow subModel ->
            Route.RegionsShow subModel.id

        RegionsShowMap subModel ->
            Route.RegionsShowMap subModel.id

        RegionsNew _ ->
            Route.RegionsNew

        RegionsEdit subModel ->
            Route.RegionsEdit subModel.id

        RegionsEditChurches subModel ->
            Route.RegionsEditChurches subModel.id

        RegionsEditDescription subModel ->
            Route.RegionsEditDescription subModel.id

        RegionsEditImages subModel ->
            Route.RegionsEditImages subModel.id

        TopicsIndex subModel ->
            Route.TopicsIndex subModel.regionId

        TopicsEdit subModel ->
            Route.TopicsEdit subModel.regionId

        TopicShow subModel ->
            Route.TopicShow subModel.regionId subModel.topicId

        TopicEdit subModel ->
            Route.TopicEdit subModel.regionId subModel.topicId

        StaticPagesIndex subModel ->
            Route.StaticPagesIndex subModel.page

        StaticPagesShow subModel ->
            Route.StaticPagesShow subModel.id

        StaticPagesEdit subModel ->
            Route.StaticPagesEdit subModel.id

        Login _ ->
            Route.Login

        NotFound _ ->
            Route.Home

        PasswordForgotten _ ->
            Route.PasswordForgotten

        Redirect _ ->
            Route.Home


toSession : Model -> Session
toSession page =
    case page of
        AccountsShow model ->
            model.session

        AccountsEdit model ->
            model.session

        AccountsIndex model ->
            model.session

        AccountsNew model ->
            model.session

        ChurchesEdit model ->
            model.session

        ChurchesEditDescription model ->
            model.session

        ChurchesShow model ->
            model.session

        ChurchesShowImage model ->
            model.session

        ChurchesShowMap model ->
            model.session

        ChurchesIndex model ->
            model.session

        MediaGalleryIndex model ->
            model.session

        MediaGalleryShow model ->
            model.session

        MediaGalleryUpload model ->
            model.session

        PoisEdit model ->
            model.session

        PoisIndex model ->
            model.session

        PoiShow model ->
            model.session

        PoiEdit model ->
            model.session

        PoiEditImages model ->
            model.session

        PoiEditKnowing model ->
            model.session

        PoiEditBelieving model ->
            model.session

        PoiEditExperiencing model ->
            model.session

        RegionsIndex model ->
            model.session

        RegionsNew model ->
            model.session

        RegionsShow model ->
            model.session

        RegionsShowMap model ->
            model.session

        RegionsEdit model ->
            model.session

        RegionsEditChurches model ->
            model.session

        RegionsEditDescription model ->
            model.session

        RegionsEditImages model ->
            model.session

        TopicsIndex model ->
            model.session

        TopicsEdit model ->
            model.session

        TopicShow model ->
            model.session

        TopicEdit model ->
            model.session

        StaticPagesEdit model ->
            model.session

        StaticPagesShow model ->
            model.session

        StaticPagesIndex model ->
            model.session

        Login model ->
            model.session

        NotFound session ->
            session

        PasswordForgotten model ->
            model.session

        Redirect session ->
            session


dirtyWarningFlash : Flash.Flash
dirtyWarningFlash =
    Flash.warning "Sie haben ungespeicherte Änderungen. Bitte \"Speichern\" oder \"Abbrechen\", um fortzufahren."


revertUrlChange : Url -> Model -> Cmd Msg
revertUrlChange url model =
    if Route.fromUrl url /= Just (toRoute model) then
        Cmd.batch
            [ Nav.replaceUrl (Session.navKey (toSession model)) (model |> toRoute |> Route.routeToString)
            , Cmd.map SessionMsg <| Session.addFlash <| dirtyWarningFlash
            ]

    else
        Cmd.none


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case ( msg, model ) of
        ( ChangedUrl url, _ ) ->
            if isDirty model then
                ( model
                , revertUrlChange url model
                )

            else
                changeRouteTo (Route.fromUrl url) model

        ( GotSession session, _ ) ->
            ( Redirect session
            , Route.replaceUrl (Session.navKey session) Route.Home
            )

        ( ClickedLink urlRequest, _ ) ->
            case urlRequest of
                Browser.Internal url ->
                    ( model
                    , Nav.pushUrl (Session.navKey (toSession model))
                        (Url.toString url)
                    )

                Browser.External href ->
                    ( model, Nav.load href )

        ( GotAccountsShowMsg subMsg, AccountsShow subModel ) ->
            AccountsShow.update subMsg subModel
                |> updateWith AccountsShow GotAccountsShowMsg

        ( GotAccountsShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotAccountsEditMsg subMsg, AccountsEdit subModel ) ->
            AccountsEdit.update subMsg subModel
                |> updateWith AccountsEdit GotAccountsEditMsg

        ( GotAccountsEditMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotAccountsIndexMsg subMsg, AccountsIndex subModel ) ->
            AccountsIndex.update subMsg subModel
                |> updateWith AccountsIndex GotAccountsIndexMsg

        ( GotAccountsIndexMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotAccountsNewMsg subMsg, AccountsNew subModel ) ->
            AccountsNew.update subMsg subModel
                |> updateWith AccountsNew GotAccountsNewMsg

        ( GotAccountsNewMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotChurchesEditMsg subMsg, ChurchesEdit subModel ) ->
            ChurchesEdit.update subMsg subModel
                |> updateWith ChurchesEdit GotChurchesEditMsg

        ( GotChurchesEditMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotChurchesEditDescriptionMsg subMsg, ChurchesEditDescription subModel ) ->
            ChurchesEditDescription.update subMsg subModel
                |> updateWith ChurchesEditDescription GotChurchesEditDescriptionMsg

        ( GotChurchesEditDescriptionMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotChurchesShowMsg subMsg, ChurchesShow subModel ) ->
            ChurchesShow.update subMsg subModel
                |> updateWith ChurchesShow GotChurchesShowMsg

        ( GotChurchesShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotChurchesShowImageMsg subMsg, ChurchesShowImage subModel ) ->
            ChurchesShowImage.update subMsg subModel
                |> updateWith ChurchesShowImage GotChurchesShowImageMsg

        ( GotChurchesShowImageMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotChurchesShowMapMsg subMsg, ChurchesShowMap subModel ) ->
            ChurchesShowMap.update subMsg subModel
                |> updateWith ChurchesShowMap GotChurchesShowMapMsg

        ( GotChurchesShowMapMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotChurchesIndexMsg subMsg, ChurchesIndex subModel ) ->
            ChurchesIndex.update subMsg subModel
                |> updateWith ChurchesIndex GotChurchesIndexMsg

        ( GotChurchesIndexMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotMediaGalleryIndexMsg subMsg, MediaGalleryIndex subModel ) ->
            MediaGalleryIndex.update subMsg subModel
                |> updateWith MediaGalleryIndex GotMediaGalleryIndexMsg

        ( GotMediaGalleryIndexMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotMediaGalleryShowMsg subMsg, MediaGalleryShow subModel ) ->
            MediaGalleryShow.update subMsg subModel
                |> updateWith MediaGalleryShow GotMediaGalleryShowMsg

        ( GotMediaGalleryShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotMediaGalleryUploadMsg subMsg, MediaGalleryUpload subModel ) ->
            MediaGalleryUpload.update subMsg subModel
                |> updateWith MediaGalleryUpload GotMediaGalleryUploadMsg

        ( GotMediaGalleryUploadMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotPoisEditMsg subMsg, PoisEdit subModel ) ->
            PoisEdit.update subMsg subModel
                |> updateWith PoisEdit GotPoisEditMsg

        ( GotPoisEditMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotPoisIndexMsg subMsg, PoisIndex subModel ) ->
            PoisIndex.update subMsg subModel
                |> updateWith PoisIndex GotPoisIndexMsg

        ( GotPoisIndexMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotPoiShowMsg subMsg, PoiShow subModel ) ->
            PoiShow.update subMsg subModel
                |> updateWith PoiShow GotPoiShowMsg

        ( GotPoiShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotPoiEditMsg subMsg, PoiEdit subModel ) ->
            PoiEdit.update subMsg subModel
                |> updateWith PoiEdit GotPoiEditMsg

        ( GotPoiEditMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotPoiEditImagesMsg subMsg, PoiEditImages subModel ) ->
            PoiEditImages.update subMsg subModel
                |> updateWith PoiEditImages GotPoiEditImagesMsg

        ( GotPoiEditImagesMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotPoiEditKnowingMsg subMsg, PoiEditKnowing subModel ) ->
            PoiEditKnowing.update subMsg subModel
                |> updateWith PoiEditKnowing GotPoiEditKnowingMsg

        ( GotPoiEditKnowingMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotPoiEditBelievingMsg subMsg, PoiEditBelieving subModel ) ->
            PoiEditBelieving.update subMsg subModel
                |> updateWith PoiEditBelieving GotPoiEditBelievingMsg

        ( GotPoiEditBelievingMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotPoiEditExperiencingMsg subMsg, PoiEditExperiencing subModel ) ->
            PoiEditExperiencing.update subMsg subModel
                |> updateWith PoiEditExperiencing GotPoiEditExperiencingMsg

        ( GotPoiEditExperiencingMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotRegionsIndexMsg subMsg, RegionsIndex subModel ) ->
            RegionsIndex.update subMsg subModel
                |> updateWith RegionsIndex GotRegionsIndexMsg

        ( GotRegionsIndexMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotRegionsShowMsg subMsg, RegionsShow subModel ) ->
            RegionsShow.update subMsg subModel
                |> updateWith RegionsShow GotRegionsShowMsg

        ( GotRegionsShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotRegionsShowMapMsg subMsg, RegionsShowMap subModel ) ->
            RegionsShowMap.update subMsg subModel
                |> updateWith RegionsShowMap GotRegionsShowMapMsg

        ( GotRegionsShowMapMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotRegionsNewMsg subMsg, RegionsNew subModel ) ->
            RegionsNew.update subMsg subModel
                |> updateWith RegionsNew GotRegionsNewMsg

        ( GotRegionsNewMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotRegionsEditMsg subMsg, RegionsEdit subModel ) ->
            RegionsEdit.update subMsg subModel
                |> updateWith RegionsEdit GotRegionsEditMsg

        ( GotRegionsEditMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotRegionsEditChurchesMsg subMsg, RegionsEditChurches subModel ) ->
            RegionsEditChurches.update subMsg subModel
                |> updateWith RegionsEditChurches GotRegionsEditChurchesMsg

        ( GotRegionsEditChurchesMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotRegionsEditDescriptionMsg subMsg, RegionsEditDescription subModel ) ->
            RegionsEditDescription.update subMsg subModel
                |> updateWith RegionsEditDescription GotRegionsEditDescriptionMsg

        ( GotRegionsEditDescriptionMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotRegionsEditImagesMsg subMsg, RegionsEditImages subModel ) ->
            RegionsEditImages.update subMsg subModel
                |> updateWith RegionsEditImages GotRegionsEditImagesMsg

        ( GotRegionsEditImagesMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotTopicsIndexMsg subMsg, TopicsIndex subModel ) ->
            TopicsIndex.update subMsg subModel
                |> updateWith TopicsIndex GotTopicsIndexMsg

        ( GotTopicsIndexMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotTopicsEditMsg subMsg, TopicsEdit subModel ) ->
            TopicsEdit.update subMsg subModel
                |> updateWith TopicsEdit GotTopicsEditMsg

        ( GotTopicsEditMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotTopicShowMsg subMsg, TopicShow subModel ) ->
            TopicShow.update subMsg subModel
                |> updateWith TopicShow GotTopicShowMsg

        ( GotTopicShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotTopicEditMsg subMsg, TopicEdit subModel ) ->
            TopicEdit.update subMsg subModel
                |> updateWith TopicEdit GotTopicEditMsg

        ( GotTopicEditMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotStaticPagesEditMsg subMsg, StaticPagesEdit subModel ) ->
            StaticPagesEdit.update subMsg subModel
                |> updateWith StaticPagesEdit GotStaticPagesEditMsg

        ( GotStaticPagesEditMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotStaticPagesShowMsg subMsg, StaticPagesShow subModel ) ->
            StaticPagesShow.update subMsg subModel
                |> updateWith StaticPagesShow GotStaticPagesShowMsg

        ( GotStaticPagesShowMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotStaticPagesIndexMsg subMsg, StaticPagesIndex subModel ) ->
            StaticPagesIndex.update subMsg subModel
                |> updateWith StaticPagesIndex GotStaticPagesIndexMsg

        ( GotStaticPagesIndexMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotLoginMsg subMsg, Login subModel ) ->
            Login.update subMsg subModel
                |> updateWith Login GotLoginMsg

        ( GotLoginMsg _, _ ) ->
            ( model, Cmd.none )

        ( GotPasswordForgottenMsg subMsg, PasswordForgotten subModel ) ->
            PasswordForgotten.update subMsg subModel
                |> updateWith PasswordForgotten GotPasswordForgottenMsg

        ( GotPasswordForgottenMsg _, _ ) ->
            ( model, Cmd.none )

        ( SessionMsg subMsg, _ ) ->
            updateSession subMsg model

        ( Ignored, _ ) ->
            ( model, Cmd.none )



---- VIEW ----


view : Model -> Document Msg
view model =
    let
        viewPage page toMsg config =
            Page.view (toSession model) toMsg SessionMsg page config
    in
    case model of
        {-
           Boring Routes
        -}
        Redirect _ ->
            viewPage Page.Other (\_ -> Ignored) Blank.view

        NotFound _ ->
            viewPage Page.Other (\_ -> Ignored) NotFound.view

        {-
           Interesting Routes
        -}
        AccountsShow subModel ->
            viewPage Page.Accounts GotAccountsShowMsg (AccountsShow.view subModel)

        AccountsEdit subModel ->
            viewPage Page.Accounts GotAccountsEditMsg (AccountsEdit.view subModel)

        AccountsIndex subModel ->
            viewPage Page.Accounts GotAccountsIndexMsg (AccountsIndex.view subModel)

        AccountsNew subModel ->
            viewPage Page.Accounts GotAccountsNewMsg (AccountsNew.view subModel)

        ChurchesEdit subModel ->
            viewPage Page.Churches GotChurchesEditMsg (ChurchesEdit.view subModel)

        ChurchesEditDescription subModel ->
            viewPage Page.Churches GotChurchesEditDescriptionMsg (ChurchesEditDescription.view subModel)

        ChurchesShow subModel ->
            viewPage Page.Churches GotChurchesShowMsg (ChurchesShow.view subModel)

        ChurchesShowImage subModel ->
            viewPage Page.Churches GotChurchesShowImageMsg (ChurchesShowImage.view subModel)

        ChurchesShowMap subModel ->
            viewPage Page.Churches GotChurchesShowMapMsg (ChurchesShowMap.view subModel)

        ChurchesIndex subModel ->
            viewPage Page.Churches GotChurchesIndexMsg (ChurchesIndex.view subModel)

        MediaGalleryIndex subModel ->
            viewPage Page.MediaGallery GotMediaGalleryIndexMsg (MediaGalleryIndex.view subModel)

        MediaGalleryShow subModel ->
            viewPage Page.MediaGallery GotMediaGalleryShowMsg (MediaGalleryShow.view subModel)

        MediaGalleryUpload subModel ->
            viewPage Page.MediaGallery GotMediaGalleryUploadMsg (MediaGalleryUpload.view subModel)

        PoisEdit subModel ->
            viewPage Page.Pois GotPoisEditMsg (PoisEdit.view subModel)

        PoisIndex subModel ->
            viewPage Page.Pois GotPoisIndexMsg (PoisIndex.view subModel)

        PoiShow subModel ->
            viewPage Page.Pois GotPoiShowMsg (PoiShow.view subModel)

        PoiEdit subModel ->
            viewPage Page.Pois GotPoiEditMsg (PoiEdit.view subModel)

        PoiEditImages subModel ->
            viewPage Page.Pois GotPoiEditImagesMsg (PoiEditImages.view subModel)

        PoiEditKnowing subModel ->
            viewPage Page.Pois GotPoiEditKnowingMsg (PoiEditKnowing.view subModel)

        PoiEditBelieving subModel ->
            viewPage Page.Pois GotPoiEditBelievingMsg (PoiEditBelieving.view subModel)

        PoiEditExperiencing subModel ->
            viewPage Page.Pois GotPoiEditExperiencingMsg (PoiEditExperiencing.view subModel)

        RegionsIndex subModel ->
            viewPage Page.Regions GotRegionsIndexMsg (RegionsIndex.view subModel)

        RegionsShow subModel ->
            viewPage Page.Regions GotRegionsShowMsg (RegionsShow.view subModel)

        RegionsShowMap subModel ->
            viewPage Page.Regions GotRegionsShowMapMsg (RegionsShowMap.view subModel)

        RegionsNew subModel ->
            viewPage Page.Regions GotRegionsNewMsg (RegionsNew.view subModel)

        RegionsEdit subModel ->
            viewPage Page.Regions GotRegionsEditMsg (RegionsEdit.view subModel)

        RegionsEditChurches subModel ->
            viewPage Page.Regions GotRegionsEditChurchesMsg (RegionsEditChurches.view subModel)

        RegionsEditDescription subModel ->
            viewPage Page.Regions GotRegionsEditDescriptionMsg (RegionsEditDescription.view subModel)

        RegionsEditImages subModel ->
            viewPage Page.Regions GotRegionsEditImagesMsg (RegionsEditImages.view subModel)

        TopicsIndex subModel ->
            viewPage Page.Topics GotTopicsIndexMsg (TopicsIndex.view subModel)

        TopicsEdit subModel ->
            viewPage Page.Topics GotTopicsEditMsg (TopicsEdit.view subModel)

        TopicShow subModel ->
            viewPage Page.Topics GotTopicShowMsg (TopicShow.view subModel)

        TopicEdit subModel ->
            viewPage Page.Topics GotTopicEditMsg (TopicEdit.view subModel)

        StaticPagesEdit subModel ->
            viewPage Page.StaticPages GotStaticPagesEditMsg (StaticPagesEdit.view subModel)

        StaticPagesShow subModel ->
            viewPage Page.StaticPages GotStaticPagesShowMsg (StaticPagesShow.view subModel)

        StaticPagesIndex subModel ->
            viewPage Page.StaticPages GotStaticPagesIndexMsg (StaticPagesIndex.view subModel)

        Login subModel ->
            viewPage Page.Login GotLoginMsg (Login.view subModel)

        PasswordForgotten subModel ->
            viewPage Page.Login GotPasswordForgottenMsg (PasswordForgotten.view subModel)



---- PROGRAM ----


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , onUrlChange = ChangedUrl
        , onUrlRequest = ClickedLink
        , subscriptions = subscriptions
        , update = update
        , view = view
        }


updateWith :
    (subModel -> Model)
    -> (subMsg -> Msg)
    -> ( subModel, Cmd subMsg, Cmd Session.Msg )
    -> ( Model, Cmd Msg )
updateWith toModel toMsg ( subModel, subCmd, cmd ) =
    ( toModel subModel
    , Cmd.batch [ Cmd.map toMsg subCmd, cmd |> Cmd.map SessionMsg ]
    )



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions model =
    Sub.batch
        [ Session.changes GotSession (toSession model)
        , Session.subscriptions (toSession model) |> Sub.map SessionMsg
        , Leaflet.groundplanClicked <| GotPoiEditMsg << PoiEdit.groundplanClicked
        ]
